.app__page_not_found {
    background-color: #F4E9DB;
    display: flex;
    width: 100%;
    height: 849px;
    padding: 0 8%;
    align-items: center;
    justify-content: space-between;
}
.app__page_not_found_message {
    width: 40%;
}


.app__page_not_found_img {
}

.app__page_not_found_title {
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 71px;

    /* Color/Purple/100% */

    color: #0D065D;

    margin-bottom: 40px;
}

.app__page_not_found_subtitle {
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 53px;

    /* Color/Purple/80% */

    color: #3D387D;
    margin-bottom: 40px;
}

.app__page_not_found_text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    /* Color/Gray/60% */

    color: #666666;
    margin-bottom: 70px;
}